import api from "./index"

/**
 * Get all Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAdvertisingRequest = (params, includes = []) => {
    return api
        .request("admin/advertising-campaign-list")
        .withParams(params)
        .withIncludes(includes)
        .get()
}

/**
 * Create Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const createAdvertisingRequest = (params, includes = []) => {
    return api
        .request("admin/advertising-campaign")
        .withParams(params)
        .withIncludes(includes)
        .post()
}

/**
 * update Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const updateAdvertising = (params, includes = []) => {
    return api
        .request("admin/advertising-campaign")
        .withParams(params)
        .withIncludes(includes)
        .put()
}

/**
 * delete Advertising
 *
 * @param id
 *
 *
 * @return {*}
 */
export const deleteAdvertising = id => {
    return api
        .request("admin/advertising-campaign")
        .withParams({
            campaign_id: id
        })
        .delete()
}

/**
 * activate Advertising
 *
 * @param params
 *
 *
 * @return {*}
 */
export const activateAdvertising = (params, includes = []) => {
    return api
        .request("admin/advertising-campaign-restore")
        .withParams(params)
        .withIncludes(includes)
        .post()
}
