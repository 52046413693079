<template>
  <div class="search-wrap">
    <v-text-field
      v-model="search"
      :counter="maxSearch ? maxSearch: false"
      solo
      :label="label"
      class="input-custom"
      append-icon="search"
      @input="searchInput"
    />
  </div>
</template>

<script>
export default {
  name: "CampaignSearch",
  props: {
    label: {
      type: String,
      default: "Search"
    },
    maxSearch: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
    search: null
  }),

  methods: {
    async searchInput() {
      if (
        this.search &&
        this.search.length &&
        this.search.length > this.maxSearch
      ) {
        this.$nextTick(() => {
          this.search = this.search.slice(0, this.maxSearch)
        })
        return
      }
      await clearTimeout(this.inputSearchTimer)
      this.inputSearchTimer = setTimeout(() => {
        this.$emit("search", this.search) 
      }, 1000)
    }
  }
}
</script>
<style scoped>
.search-wrap {
  /*flex: 0 0 264px;*/
  /*max-width: 264px;*/
  position: relative;
}

.search-wrap >>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  position: absolute;
  right: 0;
  bottom: -18px;
}

</style>
