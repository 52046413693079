import { isUndefinedOrNull } from "../utils/helpers"

const pattern = new RegExp("page=(\\d+)&per_page=(\\d+)")

/**
 * TODO: Support hash mode of vue-router
 */
export default {
  methods: {
    /**
     * Save pagination data in url
     * the goal is to not loose side data from hash
     *
     * @param page
     * @param per_page 
     */
    $paginationCacheSave(page = 1, per_page = 10) {
      const url = window.location.href.split("?")[0]
      const search = window.location.search.substr(1)
      const result = pattern.exec(search)
      if (isUndefinedOrNull(result)) {
        window.history.pushState(
          "",
          "",
          `${url}?page=${page}&per_page=${per_page}`
        )
        return
      }
      window.history.pushState(
        "",
        "",
        `${url}?page=${page}&per_page=${per_page}`
      )
    },

    /**
     * Get the pagination data
     *
     * @return {{page: number, limit: number}}
     */
    $paginationCacheGet() {
      const search = window.location.search.substr(1)
      const result = pattern.exec(search) || []
      return {
        page: result[1] ? parseInt(result[1]) : 1,
        per_page: result[2] ? parseInt(result[2]) : 10
      }
    }
  }
}
